












import { Vue, Component } from 'vue-property-decorator'
import AppQr from './views/app.vue'
import Initializer from '@/components/initializer.vue'
import axios from 'axios'
import { baseUrl } from './utils/server'
import SocialMedia from './views/social-media.vue'
import Profile from './views/profile.vue'
import Coupon from './views/coupon.vue'
import NotFound from './views/not-found.vue'
import Feedback from './views/feedback.vue'
import AudioQr from './views/audio-qr.vue'
import EventQr from './views/eventQr.vue'
import VideosQr from './views/videos.vue'
import ImageQr from './views/image.vue'
import PdfQr from './views/pdf.vue'
import MenuQr from './views/menuQr.vue'

@Component({ components: { AppQr, MenuQr, EventQr, PdfQr, ImageQr, VideosQr, Initializer, AudioQr, Feedback, Coupon, Profile, SocialMedia, NotFound } })
export default class App extends Vue {
  isGettingProject = true
  isWhitelabel = false
  logoUrl = '';
  // component = 'not-found'
  component = 'initializer';
  config = null
  id = null
  mounted () {
    const scanCode = window.location.pathname.split('/')[1]
    const host = window.location.host
    if (host !== 'scan.qrdaddy.io' && host !== 'scan.qrverse.io' && host !== 'localhost:8080') {
      axios.all([axios.get(`${baseUrl}/scan/${scanCode}`), axios.get(`${baseUrl}/wl/byHost/${host}`)]).then(response => {
        const [scan, wl] = response
        if (scan.data.success) {
          if (scan.data.data.category === 'website' || scan.data.data.category === 'google') {
            window.location.href = scan.data.data.projectData.content.websiteUrl
          } else {
            this.component = scan.data.data.category
            this.config = scan.data.data.projectData.content
            this.id = scan.data.data.id
          }
        }

        if (wl.data.success) {
          this.logoUrl = wl.data.data.logoUrl
        }
      }).finally(() => {
        this.isGettingProject = false
      })
    } else {
      axios.get(`${baseUrl}/scan/${scanCode}`).then(response => {
        const apiResponse = response.data
        console.log(apiResponse)
        if (apiResponse.success) {
          if (apiResponse.data.category === 'website' || apiResponse.data.category === 'google') {
            window.location.href = apiResponse.data.projectData.content.websiteUrl
          } else {
            this.component = apiResponse.data.category
            this.config = apiResponse.data.projectData.content
            this.id = apiResponse.data.id
          }
        }
      }).finally(() => {
        this.isGettingProject = false
      })
    }
  }

  navigate () {
    window.open('https://app.qrdaddy.io', '_blank')
  }
}
