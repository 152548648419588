


















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AppQr extends Vue {
    @Prop({ default: () => ({}) }) config!: any

    get appName () {
      return this.config.name
    }

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get description () {
      return this.config.description
    }

    get site () {
      return this.config.website
    }

    get appLogo () {
      return this.config.logo
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    navigate (os: string) {
      window.location.href = this.config.appLinks[os]
    }
}
