

























import { Vue, Component, Prop } from 'vue-property-decorator'
import Copy from 'copy-to-clipboard'

@Component
export default class CouponModal extends Vue {
    @Prop({ default: () => ({}) }) couponConfig!: any;

    get couponCode () {
      return this.couponConfig.code
    }

    get term () {
      return this.couponConfig.term
    }

    copyToClipboard () {
      Copy(this.couponCode)
      this.$toast.info('Code copied')
    }
}
