import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import weekday from 'dayjs/plugin/weekday'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(weekday)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

function dynamicFormat (date: any, format: string): string {
  return dayjs(date).format(format)
}

export {
  dayjs,
  duration,
  dynamicFormat
}
