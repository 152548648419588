



















import axios from 'axios'
import download from 'downloadjs'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Preview extends Vue {
    @Prop({ default: () => ({}) }) config!: any

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get imagesFiles () {
      return this.config.images
    }

    get title () {
      return this.config.title
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    download (file: any) {
      const request = axios.get(file.url, { responseType: 'arraybuffer' })
      request.then((response) => {
        download(response.data, file.name, 'image/*')
        // resolve(response.data);
      }).catch((error) => {
        // return reject(error)
        console.log(error)
        this.$toast.error('Could now download file')
      })
    }
}
