
























































































import { Component, Prop, Vue } from 'vue-property-decorator'

const socialMediaColors: { [social: string]: string } = {
  facebook: '#1877f2',
  twitter: '#1da1f2',
  instagram: '#5851db',
  snapchat: '#fffc00',
  linkedin: '#0077b5',
  tiktok: '#000000'
}

@Component
export default class Preview extends Vue {
    @Prop({ default: () => ({}) }) config!: any

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor,
        height: '98vh',
        overflowY: 'auto'
      }
    }

    get fullName () {
      return this.config.fullName
    }

    get job () {
      return this.config.job
    }

    get phone () {
      return this.config.phone
    }

    get email () {
      return this.config.email
    }

    get company () {
      return this.config.company
    }

    get logo () {
      return this.config.logo
    }

    get socials () {
      return this.config.socials
    }

    get site () {
      return this.config.website
    }

    get address () {
      return this.config.address
    }

    get city () {
      return this.config.city
    }

    get state () {
      return this.config.lState
    }

    get buttonColor () {
      return {
        backgroundColor: this.config.buttonColor
      }
    }

    get country () {
      return this.config.country
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    socialColor (social: any) {
      return {
        color: socialMediaColors[social]
      }
    }

    navigate (social: string) {
      window.open(social, '_blank')
    }
}
