





























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MenuQr extends Vue {
    @Prop({ default: () => ({}) }) config!: any
    get image () {
      return this.config.companyLogo
    }

    get establishment () {
      return this.config.establishment
    }

    get description () {
      return this.config.description
    }

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    get pStyle () {
      return {
        color: `${this.config.titleColor} !important`,
        textAlign: 'center'
      }
    }

    get sections () {
      return this.config.sections
    }
}
