import Vue from 'vue'
import App from './App.vue'

import 'jquery'
import 'bootstrap'
import '@popperjs/core'
import 'bootstrap/dist/css/bootstrap.css'

// import './assets/css/app.css'

import '@/scss/style.scss'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.config.productionTip = false

Vue.use(VueToast)

new Vue({
  render: h => h(App)
}).$mount('#app')
