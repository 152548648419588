















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { dayjs } from '@/utils/dayjs'

@Component
export default class Preview extends Vue {
    @Prop({ default: () => ({}) }) config!: any

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get eventTitle () {
      return this.config.title
    }

    get description () {
      return this.config.description
    }

    get externalLink () {
      return this.config.buttonLink
    }

    get bannerImage () {
      return this.config.bannerImage
    }

    get eventType () {
      return this.config.eventType
    }

    get venue () {
      return this.config.venue
    }

    get callToAction () {
      return this.config.linkButtonCta
    }

    get eventLink () {
      return this.config.eventLink
    }

    get password () {
      return this.config.password
    }

    get startTime () {
      return dayjs(this.config.startTime).format('DD MMM, YYYY. HH:mm a')
    }

    get endTime () {
      return dayjs(this.config.endTime).format('DD MMM, YYYY. HH:mm a')
    }

    get buttonText () {
      return this.config.buttonText
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    navigate (link: string) {
      window.open(link, '_blank')
    }
}
