import { render, staticRenderFns } from "./videos.vue?vue&type=template&id=2fd0d1c9&scoped=true&"
import script from "./videos.vue?vue&type=script&lang=ts&"
export * from "./videos.vue?vue&type=script&lang=ts&"
import style0 from "./videos.vue?vue&type=style&index=0&id=2fd0d1c9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd0d1c9",
  null
  
)

export default component.exports