

























import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import download from 'downloadjs'

@Component
export default class AudioQr extends Vue {
    @Prop({ default: () => ({}) }) config!: any;

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get audioFiles () {
      return this.config.audioFiles
    }

    get title () {
      return this.config.title
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    isPlaying (audio: any) {
      const audioPlayer = document.getElementById(audio.id) as HTMLAudioElement
      return audioPlayer && !audioPlayer.paused
    }

    stopAudio (audio: any) {
      const audioPlayer = document.getElementById(audio.id) as HTMLAudioElement
      audioPlayer.pause()
      audioPlayer.currentTime = 0
    }

    playAudio (audio: any) {
      const audioPlayer = document.getElementById(audio.id) as HTMLAudioElement
      if (audioPlayer.paused) {
        audioPlayer.play()
      } else {
        audioPlayer.pause()
      }
    }

    download (file: any) {
      const request = axios.get(file.url, { responseType: 'arraybuffer' })
      request.then((response) => {
        download(response.data, file.name, 'audio/*')
        // resolve(response.data);
      }).catch((error) => {
        // return reject(error)
        console.log(error)
        this.$toast.error('Could now download file')
      })
    }
}
