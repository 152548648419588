
































import { baseUrl } from '@/utils/server'
import axios from 'axios'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { StarRating } from 'vue-rate-it'
import { v4 as uuid } from 'uuid'

@Component({ components: { StarRating } })
export default class Preview extends Vue {
    @Prop({ default: () => ({}) }) config!: any
    @Prop({ default: () => ({}) }) id: any

    name = ''
    email = ''
    feedback = ''
    rating = 0

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get title () {
      return this.config.title
    }

    get logo () {
      return this.config.logo
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    sendFeedback () {
      if (this.rating === 0) {
        this.$toast.error('Please do a rating')
        return
      }
      const payload = {
        id: uuid(),
        projectId: this.id,
        name: this.name,
        email: this.email,
        feedback: this.feedback,
        rating: this.rating === 0 ? 1 : this.rating
      }

      axios.post(`${baseUrl}/feedback`, payload)
        .then(response => {
          this.$toast.success('Feedback submitted')
        })
    }
}
