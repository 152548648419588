





























import dayjs from 'dayjs'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CouponModal from './couponModal.vue'
import { Modal } from 'bootstrap'
import { dynamicFormat } from '@/utils/dayjs'

const formatDate = (date: any) => {
  return dayjs(date).format('DD-MMM-YYYY')
}

@Component({ components: { CouponModal } })
export default class Preview extends Vue {
    @Prop({ default: () => ({}) }) config!: any

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get logo () {
      return this.config.logo
    }

    get title () {
      return this.config.title
    }

    get description () {
      return this.config.description
    }

    get effectiveDate () {
      if (this.config.dateFormat) {
        return dynamicFormat(this.config.expiryDate, this.config.dateFormat)
      } else {
        return formatDate(this.config.expiryDate)
      }
    }

    get term () {
      return this.config.term
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    openCoupon () {
      new Modal(document.getElementById('couponModal')!)!.show()
    }
}

