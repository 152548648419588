












import { Vue, Component, Prop } from 'vue-property-decorator'
import getVideoId from 'get-video-id'

@Component
export default class VideosQr extends Vue {
    @Prop({ default: () => ({}) }) config!: any

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get title () {
      return this.config.title
    }

    get description () {
      return this.config.description
    }

    get videoSource () {
      return this.config.videoSource
    }

    get videoLink () {
      return this.config.videoLink
    }

    get textStyle () {
      return {
        fontFamily: this.config.titleFont,
        fontSize: `${this.config.titleSize}px`,
        color: `${this.config.titleColor} !important`
      }
    }

    getVideoSrc () {
      if (this.videoLink.length > 0) {
        const videoDetails = getVideoId(this.videoLink)
        if (videoDetails.service === 'youtube') return `https://www.youtube.com/embed/${videoDetails.id}`
        if (videoDetails.service === 'dailymotion') return `https://www.dailymotion.com/embed/video/${videoDetails.id}`
        if (videoDetails.service === 'vimeo') return `https://player.vimeo.com/video/${videoDetails.id}`
        return ''
      }

      return ''
    }
}
