






















import { Vue, Component, Prop } from 'vue-property-decorator'

const socialMediaColors: { [social: string]: string } = {
  facebook: '#1877f2',
  twitter: '#1da1f2',
  instagram: '#5851db',
  snapchat: '#fffc00',
  linkedin: '#0077b5',
  tiktok: '#000000'
}

@Component
export default class SocialMedia extends Vue {
    @Prop({ default: () => ({}) }) config!: any

    get appName () {
      return this.config.name
    }

    get pageColor () {
      return {
        backgroundColor: this.config.pageColor
      }
    }

    get socials () {
      return this.config.socials
    }

    get site () {
      return this.config.website
    }

    socialColor (key: string) {
      return {
        color: socialMediaColors[key]
      }
    }

    navigate (social: string) {
      window.open(social, '_blank')
    }
}
